import React from "react";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

export default function NewMediaPlayer(props) {
  const customStyles = `
    .rhap_container {
      font-family: 'Manrope';
      border-radius: 8px;
    }
    .rhap_time {
      color: #82858F;
      font-weight: 800;
      line-height: 14px;
      letter-spacing: 0.56px; 
    }
    .rhap_progress-bar {
      height: 4px;
      border-radius: 4px;
    }
    .rhap_progress-filled {
      background-color: #99DDB8;
    }
    .rhap_progress-indicator {
      width: 12px;
      height: 12px;
      margin-left: -6px;
      top: -4px;
      background: #99DDB8;
      box-shadow: none;
    }
  `;

  return (
    <div className="w-full rounded-lg">
      <style>{customStyles}</style>
      <AudioPlayer
        src={props.url}
        layout="horizontal-reverse"
        showJumpControls={false}
        customIcons={{
          play: (
            <img
              style={{ height: "20px", width: "20px" }}
              src={require("../assets/icons/play-filled-green.png")}
            />
          ),
          pause: (
            <img
              style={{ height: "20px", width: "20px" }}
              src={require("../assets/icons/pause-filled-green.png")}
            />
          ),
        }}
        customVolumeControls={[]}
        customAdditionalControls={[]}
        customProgressBarSection={[
          RHAP_UI.CURRENT_TIME,
          RHAP_UI.PROGRESS_BAR,
          RHAP_UI.DURATION,
          <div className="w-8"></div>,
        ]}
        hasDefaultKeyBindings={false}
        style={{ backgroundColor: "#FFFFFF", fontFamily: "PT Sans" }}
        onPlay={props.onPlay}
        onEnded={() => props.onEnded && props.onEnded()}
      />
    </div>
  );
}
