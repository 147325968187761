import React, { useEffect } from "react";
import vaniLogo from "../assets/icons/vani-logo-full.svg";
import { Toaster } from "react-hot-toast";

export default function LeadsStatus() {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const encodedData = params.get("data");

    if (encodedData) {
      fetch(`${process.env.REACT_APP_THOR_URL}/leads_status_updated`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(encodedData),
      }).catch((error) => {
        console.error("Error:", error);
      });
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full h-dvh px-8 pt-8 space-y-6 font-sans">
      <Toaster position="bottom-center" />
      <img src={vaniLogo} alt="vani-logo" />
      <div className="text-orange1 font-bold text-2xl">
        Thank you for your confirmation!
      </div>
    </div>
  );
}
