import React from "react";
import { Route, Routes, useParams, useLocation } from "react-router-dom";
import { motion } from "framer-motion";

import Feedback from "./screens/Feedback";
import ScenarioAnalysis from "./screens/ScenarioAnalysis";
import LearnerScenarios from "./screens/LearnerScenarios";
import RolePlay from "./screens/RolePlay";

import NewFeedback from "./screens/NewFeedback";
import TranscriptionReview from "./screens/NewFeedback/TranscriptionReview";
import SentenceCheck from "./screens/NewFeedback/SentenceCheck";
import EnhanceArticulation from "./screens/NewFeedback/EnhanceArticulation";
import GrammarCheck from "./screens/NewFeedback/GrammarCheck";
import Error from "./screens/Error";
import CustomisePlaybook from "./screens/CustomisePlaybook";
import RolePlayChat from "./screens/widgets/RolePlay/RolePlayChat";
import AiRoleplay from "./screens/AiRoleplay";
import ProgramOverview from "./screens/ProgramOverview";
import Congratulation from "./screens/Congratulations";
import EvaluateError from "./screens/EvaluateError";
import Welldone from "./screens/Welldone";
import AiFeedback from "./screens/AiFeedback";
import ContactVerification from "./screens/ContactVerification";
import ContactVerified from "./screens/ContactVerified";
import LeadsStatus from "./screens/LeadsStatus";
import AiRoleplayVapi from "./screens/AiRoleplayVapi";

const PageTransition = ({ children }) => {
  const location = useLocation();

  return (
    <motion.main
      className="main__container mobile-container relative"
      key={location.pathname}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 2 }}
    >
      {children}
    </motion.main>
  );
};

function App() {
  return (
    <PageTransition>
      <Routes>
        <Route path="/" element={<Feedback />} />
        <Route path="/ai-assessment" element={<NewFeedback />} />
        <Route path="/ai-assessment/:id" element={<DynamicAIAssessment />} />
        <Route path="/scenario-analysis" element={<ScenarioAnalysis />} />
        <Route path="/learner-scenarios" element={<LearnerScenarios />} />
        <Route path="/program-overview" element={<ProgramOverview />} />
        <Route path="/congratulations" element={<Congratulation />} />
        <Route path="/evaluate/error" element={<EvaluateError />} />
        <Route path="/evaluate/welldone" element={<Welldone />} />
        <Route path="/evaluate/aifeedback" element={<AiFeedback />} />
        <Route
          path="/vocabulary"
          element={
            <RolePlayChat roleplay_situation_uid="vocabulary_practice" />
          }
        />
        <Route path="/role-play" element={<RolePlay />} />
        <Route path="/ai-roleplay" element={<AiRoleplay />} />
        <Route path="/ai-roleplay-vapi" element={<AiRoleplayVapi />} />
        <Route
          path="/generate-situations-from-roleplay"
          element={<CustomisePlaybook />}
        />
        <Route path="/contact-verification" element={<ContactVerification />} />
        <Route path="/contact-verified" element={<ContactVerified />} />
        <Route path="/leads-status" element={<LeadsStatus />} />
        <Route path="/error" element={<Error />} />
      </Routes>
    </PageTransition>
  );
}

const DynamicAIAssessment = () => {
  const { id } = useParams();

  switch (id) {
    case "suggested_speech_json":
      return <TranscriptionReview />;
    case "sentence_comparison_json":
      return <SentenceCheck />;
    case "vocabulary_json":
      return <EnhanceArticulation />;
    case "grammer_json":
      return <GrammarCheck />;
    default:
      return <div>Invalid</div>;
  }
};

export default App;
