import React, { useState, useEffect } from "react";
import { getSentenceComparisonData } from "../../api/services/ai";
import { useNavigate } from "react-router-dom";

const SentenceCompare = ({ data }) => {
  return (
    <div>
      <div className="flex flex-col rounded-t-lg p-5 border border-grey2 border-b-0 leading-[150%]">
        <div className="flex space-x-4">
          <img
            className="h-6 w-6"
            alt="tick"
            src={require("../../assets/icons/info-filled-orange.png")}
          />
          <div className="flex flex-col space-y-1">
            <h1 className="uppercase text-base font-bold text-darkgray font-sans">
              original
            </h1>
            <p></p>
            <p className="text-darkgray text-base font-sans">{data.original}</p>
          </div>
        </div>
        {data.reason && (
          <div className="flex items-center space-x-4">
            <div className="h-6 w-6"></div>
            <div className="h-0 w-full border-t border-grey2" />
          </div>
        )}
        {data.reason && (
          <div className="flex space-x-4">
            <img
              className="h-6 w-6"
              alt="tick"
              src={require("../../assets/icons/info-filled-grey.png")}
            />
            <p className="text-darkgray text-base italic font-sans">
              {data.reason}
            </p>
          </div>
        )}
      </div>
      <div className="bg-green3 rounded-b-lg flex p-5 border border-grey2 border-t-0 space-x-4 leading-[150%]">
        <img
          className="h-6 w-6"
          alt="tick"
          src={require("../../assets/icons/tick-filled-green.png")}
        />
        <div className="flex flex-col space-y-1">
          <h1 className="uppercase text-base font-bold text-darkgray font-sans">
            suggested
          </h1>
          <p></p>
          <p className="text-darkgray text-base font-sans">{data.suggested}</p>
        </div>
      </div>
    </div>
  );
};

export default function SentenceCheck() {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const userId = params.get("userId");
  const assessmentId = params.get("assessmentId");
  const userType = params.get("userType") || params.get("usertype");
  const flow = params.get("flow");

  const [sentenceComparisonData, setSentenceComparisonData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const comparisonData = await getSentenceComparisonData(
          userId,
          assessmentId,
          userType
        );

        setSentenceComparisonData(comparisonData.sentences);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleNavigate = () => {
    const url = `/ai-assessment/?userId=${userId}&assessmentId=${assessmentId}&userType=${userType}&flow=${flow}`;
    navigate(url, { state: { indext: 1 } });
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-screen py-10 space-y-6">
      <div className="flex items-center justify-between w-full px-8">
        <div className="relative flex align-middle justify-center w-full">
          <h1 className="text-2xl font-semibold text-darkgray">
            Sentence Check
          </h1>
          <img
            className="h-9 w-9 absolute left-0 cursor-pointer"
            alt="cross"
            src={require("../../assets/icons/arrow-left-outlined-grey.png")}
            onClick={handleNavigate}
          />
        </div>
      </div>
      <div className="flex-1 overflow-y-scroll px-8 space-y-6">
        {sentenceComparisonData &&
          sentenceComparisonData.map((sentenceComparison, index) => (
            <SentenceCompare data={sentenceComparison} key={index} />
          ))}
      </div>
    </div>
  );
}
