import React, { useState, useEffect } from "react";

import { getVocabularyData } from "../../api/services/ai";
import { useNavigate } from "react-router-dom";

const Vocabulary = ({ data }) => {
  const { old_word, new_word, old_phrase, new_phrase, reason } = data;

  const formatPhrase = (phrase, word, colorClass) => {
    const words = phrase.split(" ");
    return words.map((w, index) => (
      <span
        key={index}
        className={
          w === word
            ? `text-${colorClass} font-bold font-sans`
            : "text-darkgray font-sans"
        }
      >
        {w}
        {index !== words.length - 1 && " "}
      </span>
    ));
  };

  return (
    <div>
      <div className="rounded-t-lg flex flex-col p-5 border border-grey2 border-b-0 space-y-4 leading-[150%]">
        <div className="flex items-center space-x-6">
          <p className="text-base text-primary font-bold">{old_word}</p>
          <img
            className="w-4 h-4"
            src={require("../../assets/icons/arrow-right-outlined-grey.png")}
            alt="arrow-right"
          />
          <p className="text-sm text-green1 font-bold">{new_word}</p>
        </div>
        {reason && <p className={"text-darkgray font-sans"}>{reason}</p>}
      </div>
      <div className="bg-green3 rounded-b-lg flex flex-col p-5 border border-grey2 border-t-0 space-y-4 leading-[150%]">
        <div className="flex space-x-2">
          <img
            className="h-6 w-6"
            alt="info"
            src={require("../../assets/icons/info-filled-orange.png")}
          />
          <p className="text-base">
            {formatPhrase(old_phrase, old_word, "primary")}
          </p>
        </div>
        <div className="flex space-x-2">
          <img
            className="h-6 w-6"
            alt="info"
            src={require("../../assets/icons/tick-filled-green.png")}
          />
          <p className="text-base">
            {formatPhrase(new_phrase, new_word, "green1")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default function EnhanceArticulation() {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const userId = params.get("userId");
  const assessmentId = params.get("assessmentId");
  const flow = params.get("flow");
  const userType = params.get("userType") || params.get("usertype");

  const [vocabularySpeechData, setVocabularySpeechData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const vocabularyData = await getVocabularyData(
          userId,
          assessmentId,
          userType
        );
        setVocabularySpeechData(vocabularyData.vocabulary_improvement);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleNavigate = () => {
    const url = `/ai-assessment/?userId=${userId}&assessmentId=${assessmentId}&userType=${userType}&flow=${flow}`;
    navigate(url, { state: { indext: 1 } });
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-screen py-10 space-y-6">
      <div className="flex items-center justify-between w-full px-8">
        <div className="relative flex align-middle justify-center w-full">
          <h1 className="text-2xl font-semibold text-darkgray">
            Enhanced Articulation
          </h1>
          <img
            className="h-9 w-9 absolute left-0 cursor-pointer"
            alt="cross"
            src={require("../../assets/icons/arrow-left-outlined-grey.png")}
            onClick={handleNavigate}
          />
        </div>
      </div>
      <div className="flex-1 overflow-y-scroll px-8 space-y-6">
        {vocabularySpeechData &&
          vocabularySpeechData.map((vocabularySpeech, index) => (
            <Vocabulary data={vocabularySpeech} key={index} />
          ))}
      </div>
    </div>
  );
}
